.tribute {
  background-color: #dcecf4;
  padding: 3rem 0 4rem;
}

.tribute .button_container {
  margin-top: 1.3rem;
  gap: 2rem;
}
.tribute button {
  font-weight: 300;
  padding: 0.5rem 3rem;
}

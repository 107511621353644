.wrapper {
  background: #fff;
  padding: 0.8rem 1.6rem;
  border-radius: 3rem;
  gap: 1rem;
}
.img_container img {
  display: block;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
}
.text_container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.text_container span,
.text_container p,
.text_container .bar {
  margin: 0;
  font-weight: 400;
  font-size: 2rem;
  color: #abc9db;
}
.text_container .bar {
  margin: 0 0.8rem;
}
.text_container .event {
  color: #6097bf;
  margin-right: 0.8rem;
}
.pill_button {
  background: #6097bf;
  color: #fff;
  padding: 0.5rem 1.5rem;
  font-size: 1.2rem;
  font-weight: 400;
  border: none;
  border-radius: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.pill_button:hover {
  background: #1a4e74;
}

@media screen and (max-width: 768px) {
  .wrapper {
    padding: 0.6rem 1.2rem;
    border-radius: 2rem;
  }
  .img_container img {
    display: block;
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
  }
  .text_container span,
  .text_container p,
  .text_container .bar {
    font-size: 1.6rem;
  }
}

.social_login {
  display: flex;
  justify-content: center;
  gap: 3rem;
}
.social_btn {
  font-weight: 300;
  font-size: 2rem;
  border-radius: 1rem;
}
.social_btn img {
  width: 3rem;
}
/* .socialLogin {
  background: #fff;
  border-radius: 1rem;
  color: #1A4E74;
  font-weight: 300;
  font-size: 2rem;
}
.socialLogin:hover {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
}
.socialLogin:hover {
  color: #1A4E74;
} */
@media only screen and (max-width: 992px) {
  .social_login {
    flex-direction: column-reverse;
  }
}

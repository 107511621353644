.header {
  position: fixed;
  top: 0;
  z-index: 15;
  width: 100%;
}
.wrapper {
  padding: 2rem 12rem;
  background: linear-gradient(
    180deg,
    rgba(26, 78, 116, 0.7) 0%,
    rgba(26, 78, 116, 0.4) 51.79%,
    rgba(26, 78, 116, 0) 100%
  );
  position: relative;
}
.login_remainder {
  background: #1a4e74;
  padding: 1rem 2rem;
}
.login_remainder p {
  font-size: 2rem;
  font-weight: 300;
  text-align: center;
  color: #fff;
  direction: rtl;
}
.login_remainder p span {
  font-weight: 600;
}
@media (max-width: 768px) {
  .login_remainder p {
    font-size: 1.6rem;
  }
}
.header img {
  cursor: pointer;
}
.logo {
  width: 18rem;
}
.desktop_input {
  font-weight: 300;
  font-size: 1.8rem;
  padding: 0.1rem 0.5rem;
  color: #f5fcff !important;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #fff;
  border-radius: 0;
  direction: rtl;
}
.desktop_input::placeholder {
  color: #f5fcff;
}
.desktop_input:focus {
  background-color: transparent;
  border-bottom: 1px solid #fff;
  outline: none;
  box-shadow: none;
}
.header_btn {
  font-weight: 300;
  font-size: 1.6rem;
  padding: 0.1rem 1.5rem;
  direction: rtl;
  color: #1a4e74;
  background: #fff;
  border-radius: 2rem;
  border: 1px solid #fff;
  transition: 0.3s all ease-in;
}
.header_btn_active {
  background: transparent;
  color: #fff;
  transition: 0.3s all ease-in;
}
.header_btn:hover {
  background: transparent;
  color: #fff;
}
.header_btn_active:hover {
  background: #fff;
  color: #1a4e74;
}
.user_img {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  border: 2px solid #fff;
}
.menu_burger {
  width: 4rem;
  cursor: pointer;
  height: 4rem;
}
.navigation {
  padding: 0;
  position: absolute;
  right: 0%;
  width: max-content;
  z-index: 2;
}
.navigation li {
  background: #ffffff;
  border: 0.5px solid #1a4e74;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  padding: 0.6rem 3rem;
  margin-top: 1rem;
  transition: all 0.3s ease-in;
}
.navigation li a,
.navigation li span {
  display: block;
  font-weight: 200;
  font-size: 2.2rem;
  color: #1a4e74;
  transition: all 0.3s ease-in;
  direction: rtl;
  text-align: right;
  cursor: pointer;
}
.navigation li:hover {
  background: #1a4e74;
}
.navigation li:hover a,
.navigation li:hover span {
  color: #fff;
}

.search_btn {
  border: none;
}
.search_btn img {
  height: 2rem;
  width: auto;
}
.search2 .search_wrapper {
  position: absolute;
  top: 80%;
  left: 0;
  width: 100%;
  padding: 3rem;
  transform: translateY(3rem);
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
  z-index: -2;
  background-color: #1a4e74;
}
.search2 .search_wrapper.active {
  z-index: 2;
  opacity: 1;
  transform: translateY(0);
}

.search2 .search_results2 {
  width: 100%;
  position: static;
}

.search_input {
  background-color: #dcecf4;
  font-weight: 300;
  border: 0;
  border-radius: 1rem;
  padding: 0.5rem 1.5rem;
  font-size: 1.8rem;
  text-align: right;
  color: #1a4e74;
}
.search_results {
  width: 18.5rem;
  top: 6.5rem;
}

/* Media Query */
@media only screen and (max-width: 1200px) {
  .wrapper {
    padding: 2rem 2%;
  }
}
@media only screen and (max-width: 768px) {
  .logo {
    width: 15rem;
  }
  .form .desktop_input,
  .header_btn {
    display: none;
  }
  .menu_burger {
    width: 3.5rem;
    height: 3.5rem;
  }
  .navigation li {
    margin-top: 0.6rem;
    padding: 0.4rem 2.5rem;
  }
  .navigation li a {
    font-size: 2.2rem;
  }
}
